import { type FullZustandState, type UserEvent } from '../../../types';
import { type KeyboardLayout, type ShortcutId } from '../../../types/keyboardShortcuts';
import { updateState } from '../../models';
import { resetKeyboardShortcutsFromState } from '../../stateUpdateHelpers';
import { createToast } from '../../toasts.platform';

export const setKeyboardLayout = (keyboardLayout: KeyboardLayout) => {
  updateState(
    (state) => {
      state.persistent.keyboardLayout = keyboardLayout;

      resetKeyboardShortcutsFromState(state);
    },
    { eventName: 'set-keyboard-layout', userInteraction: 'click' },
  );
};

export const resetKeyboardShortcuts = () => {
  updateState(
    (state) => {
      resetKeyboardShortcutsFromState(state);
    },
    { eventName: 'reset-keyboard-shortcuts', userInteraction: 'click' },
  );
};

export const addShortcutToBlackList = ({ shortcutId }: { shortcutId: ShortcutId; }) => {
  updateState(
    (state) => {
      if (!state.persistent.keyboardShortcutsBlackList) {
        state.persistent.keyboardShortcutsBlackList = [];
      }

      state.persistent.keyboardShortcutsBlackList.push(shortcutId);
    },
    { eventName: 'add-shortcut-to-blacklist', userInteraction: 'click' },
  );
};

export const addShortcutToBlackListFromState = ({
  shortcutId,
  state,
}: { shortcutId: ShortcutId; state: FullZustandState; }) => {
  if (!state.persistent.keyboardShortcutsBlackList) {
    state.persistent.keyboardShortcutsBlackList = [];
  }

  state.persistent.keyboardShortcutsBlackList.push(shortcutId);
};

export const removeShortcutFromBlackListFromState = ({
  shortcutId,
  state,
}: { shortcutId: ShortcutId; state: FullZustandState; }) => {
  if (!state.persistent.keyboardShortcutsBlackList) {
    state.persistent.keyboardShortcutsBlackList = [];
  }

  state.persistent.keyboardShortcutsBlackList = state.persistent.keyboardShortcutsBlackList.filter(
    (id) => id !== shortcutId,
  );
};

export const addCustomKeyboardShortcut = async ({
  shortcutId,
  keys,
  showToast = true,
}: { shortcutId: ShortcutId; keys: string; showToast?: boolean; }) => {
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.customKeyboardShortcuts) {
        state.persistent.customKeyboardShortcuts = {};
      }

      if (!state.persistent.customKeyboardShortcuts[shortcutId]) {
        state.persistent.customKeyboardShortcuts[shortcutId] = [];
      }

      state.persistent.customKeyboardShortcuts[shortcutId].push(keys);

      removeShortcutFromBlackListFromState({ shortcutId, state });
    },
    { eventName: 'add-custom-keyboard-shortcut', userInteraction: 'click' },
  );

  if (showToast) {
    createToast({
      content: 'Custom keyboard shortcut added',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const removeCustomKeyboardShortcut = async ({
  shortcutId,
  keys,
}: { shortcutId: ShortcutId; keys: string; }) => {
  const updateResult = await updateState(
    (state) => {
      if (!state.persistent.customKeyboardShortcuts) {
        state.persistent.customKeyboardShortcuts = {};
      }

      if (!state.persistent.customKeyboardShortcuts[shortcutId]) {
        state.persistent.customKeyboardShortcuts[shortcutId] = [];
      }

      if (state.persistent.customKeyboardShortcuts[shortcutId].includes(keys)) {
        state.persistent.customKeyboardShortcuts[shortcutId] = state.persistent.customKeyboardShortcuts[
          shortcutId
        ].filter((k) => k !== keys);
      } else {
        // TODO: check if it's an alias and only remove that one
        addShortcutToBlackListFromState({ shortcutId, state });
      }
    },
    { eventName: 'remove-custom-keyboard-shortcut', userInteraction: 'click' },
  );

  createToast({
    content: 'Custom keyboard shortcut removed',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const resetCustomKeyboardShortcut = async ({ shortcutId }: { shortcutId: ShortcutId; }) => {
  const updateResult = await updateState(
    (state) => {
      if (
        state.persistent.customKeyboardShortcuts &&
        state.persistent.customKeyboardShortcuts[shortcutId]
      ) {
        delete state.persistent.customKeyboardShortcuts[shortcutId];
      }

      if (state.persistent.keyboardShortcutsBlackList) {
        state.persistent.keyboardShortcutsBlackList = state.persistent.keyboardShortcutsBlackList.filter(
          (id) => id !== shortcutId,
        );
      }
    },
    { eventName: 'reset-custom-keyboard-shortcut', userInteraction: 'click' },
  );

  createToast({
    content: 'Shortcut reset',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};
