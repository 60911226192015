import React, { useMemo, useState } from 'react';
import { useKeyboardLayout } from 'shared/foreground/stateHooks';
import {
  addCustomKeyboardShortcut,
  addShortcutToBlackList,
  resetKeyboardShortcuts,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/keyboardShortcuts';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useShortcutsMap } from '../../utils/shortcuts';
import { ResetKeyboardLayoutDialog } from '../ResetKeyboardLayoutDialog';
import { UpdateShortcutsDialog } from '../UpdateShortcutsDialog';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './ShortcutsOptionsDropdown.module.css';

export default function ShortcutsOptionsDropdown() {
  const keyboardLayout = useKeyboardLayout();
  const shortcutsMap = useShortcutsMap({ onlyGetCustomShortcuts: true });
  const [isOpen, setIsOpen] = useState(false);
  const [isResetKeyboardLayoutDialogOpen, setIsResetKeyboardLayoutDialogOpen] = useState(false);
  const [shortcutsToImport, setShortcutsToImport] = useState<string | undefined>(undefined);

  const importShortcuts = () => {
    if (!shortcutsToImport) {
      return;
    }

    resetKeyboardShortcuts();

    const shortcuts: { [key: string]: string[]; } = JSON.parse(shortcutsToImport);

    Object.entries(shortcuts).forEach(([shortcutId, keys]) => {
      if (!ShortcutId[shortcutId]) {
        return;
      }

      if (keys.length) {
        keys.forEach((key) => {
          addCustomKeyboardShortcut({
            shortcutId: shortcutId as ShortcutId,
            keys: key.toString(),
            showToast: false,
          });
        });
      } else {
        addShortcutToBlackList({ shortcutId: shortcutId as ShortcutId });
      }
    });
  };

  const onReaderLoad = (event: ProgressEvent<FileReader>) => {
    if (!event.target) {
      return;
    }

    setShortcutsToImport(event.target.result as string);
  };

  const options = useMemo(() => {
    return [
      {
        type: DropdownOptionType.Item,
        name: 'Import shortcuts',
        onSelect: () => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'application/json';

          input.onchange = (event) => {
            if (!event.target) {
              return;
            }

            const reader = new FileReader();
            reader.onload = onReaderLoad;
            const target = event.target as HTMLInputElement;
            if (target.files) {
              const file = target.files[0];
              reader.readAsText(file);
            }
          };
          input.click();
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Export shortcuts',
        onSelect: () => {
          const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(shortcutsMap),
          )}`;
          const link = document.createElement('a');
          link.href = jsonString;
          link.download = 'Reader Shortcuts.json';

          link.click();
        },
      },
      {
        type: DropdownOptionType.Separator,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Reset to default',
        isDanger: true,
        onSelect: () => {
          setIsResetKeyboardLayoutDialogOpen(true);
        },
      },
    ];
  }, [shortcutsMap]);

  return (
    <>
      <Dropdown
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        appendToDocumentBody
        contentClassName={styles.dropdownContent}
        triggerClassName={styles.triggerElement}
      />

      <ResetKeyboardLayoutDialog
        isOpen={isResetKeyboardLayoutDialogOpen}
        onConfirm={() => {
          resetKeyboardShortcuts();
          setIsResetKeyboardLayoutDialogOpen(false);
        }}
        onCancel={() => setIsResetKeyboardLayoutDialogOpen(false)}
        keyboardLayout={keyboardLayout}
      />
      <UpdateShortcutsDialog
        isOpen={Boolean(shortcutsToImport)}
        onConfirm={() => {
          importShortcuts();
          setShortcutsToImport(undefined);
        }}
        onCancel={() => setShortcutsToImport(undefined)}
      />
    </>
  );
}
